.home-onboarding_projects{
	font-size: 14px;
	margin: 20px 0;

	// container, row, col
	.row{
		align-items: center;
	}

	// custom css
	.onboarding_projects_title{
		background: #4d6380;
		border: 1px solid rgba(77,99,128,.15);
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		border-radius: 20px;
		padding: 2px;

		span{
			color: white;
			font-weight: 600;
		}
	}
	
	.onboarding_projects{
		display: flex;
		align-items: center;
		border-radius: 20px;
		border: 1px solid rgba(77,99,128,.15);
		background-color: rgba(216,221,228,.4);
		overflow: hidden;
		padding: 0px;
		height: 40px;

		span{
			// white-space: nowrap;
			margin: 0 10px;
			text-transform: capitalize;
		}
		
		img{
			height: 38px;
			width: 38px;
			background: white;
			border-radius: 19px;
			object-fit: cover;
		}
	}
}

@media screen and (min-width: 480px) {
	.home-onboarding_projects .col-md-3{
		flex:0 0 20%;
		max-width: 20%;
	}

	.home-onboarding_projects .col-md-9{
		flex:0 0 80%;
		max-width: 80%;
	}
}

@media screen and (max-width: 480px) {
	.home-onboarding_projects .col-md-3{
		display: none;
	}
}