.project-security-rates{
  margin-bottom: 30px;
}

.project-security-rates .key-security-rates-wrapper{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 15px;
  align-items: flex-start;

  .key-security-rates-left{

    p{
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      color: rgba(53,69,89,.8);
      font-size: 12px;
      
      span{
        font-size: 12px;
        font-weight: 500;
        font-family: 'Inter';
      }
    }
  }

  .key-security-rates-right{
    display: flex;
    justify-content: space-between;
    gap: 15px;

    p{
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      color: rgba(53,69,89,.8);
      
      span{
        font-size: 12px;
        font-weight: 500;
        font-family: 'Inter';
      }
    }

    button{
      border-radius: 5px;
      background-color: rgba(77, 99, 128, 0.1);
      border-color: transparent;      
      color: rgb(77, 99, 128);
      padding:4px 6px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img{
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }

      p{
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #4d6380;
        margin-bottom: 0;
      }
    }
  }
}