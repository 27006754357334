.home-tabs-container{
	margin-bottom: 25px;

	.home-tabs-navigation{
		border: none;
		gap:15px;
		flex-wrap: wrap;
		display: inline-flex;
	}

	.home-tabs-content{
		background-color: white;
		box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
		// overflow-x: scroll;
	}

	.tabs-navigation{
		background-color: #EAEBED;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
			
		&.active{
			background-color: white;
		}
		
		.nav-link{
			font-weight: 600;
			font-size: 16px;
			color: #354559;
			border: none;
			padding-left: 15px;
			padding-right: 15px;
			display: flex;
			align-items: center;
			gap:20px;

			&:focus, &:hover{
				border: none;
				cursor: pointer;
			}
		}
	}

	//ant-design
	.ant-table-thead > tr > th{
		font-weight: 600;
		text-align: center;
	}
	.ant-table-thead > tr > th,
	.ant-table-tbody > tr > td{
		border: none;
		p{
			margin:0;
			text-transform: capitalize;
			font-size: 12px;
		}
	}
	.ant-table-tbody > tr > td{
		padding: 10px 15px;
		
		.project-icon{
			display: flex;
			align-items: center;
			width: 180px;
		}

		.project-icon-img{
			border-radius: 20px;
			overflow: hidden;
		}

		.project-icon-img img{
			object-fit: cover;
			width: 40px;
			height: 40px;
		}

		.project-icon a{
			padding-left: 15px;
			text-transform: capitalize;
			color: rgba(0, 0, 0, 0.85);
		}
	}
	.ant-table-tbody> tr:hover > td{
		background-color: #eaebed !important;
	}
	.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
		display: none;
	}
	.ant-table-row:nth-child(2n){
		background-color: #fafafa;
	}
	.ant-pagination-disabled .ant-pagination-item-link, 
	.ant-pagination-disabled:hover .ant-pagination-item-link, 
	.ant-pagination-disabled:focus-visible .ant-pagination-item-link,
	.ant-pagination-prev .ant-pagination-item-link, 
	.ant-pagination-next .ant-pagination-item-link{
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
	}
	.ant-pagination-item-active{
		border: none;
	}
}