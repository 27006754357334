.certik-theme{
    .navbar-dark{
        background-color: rgb(53, 69, 89);
    }
    .navbar-dark .navbar-brand img{
        height: auto;
        max-width: 150px;
    }
    .navbar-dark .navbar-nav .nav-link.certik-link{
        color: white;
    }
}