.home-community_updates{
	margin-bottom: 15px;
	
	.home-community_updates_heading{
		h4{
			margin-bottom: 15px;
			font-size: 16px;
		}
	}

	.home-community_updates_content{
		.community-updates{
			margin-bottom: 15px;
			position: relative;
			border-radius: 5px;
			padding:12px;
			background-color: white;
			box-shadow: 0 2px 16px rgba(102, 78, 78, 0.05);
	
		h4{
			font-size: 12px;
			font-family: "Inter";
		}
		p{
			font-family: "Inter";
			font-size: 12px;
		}

		span{
			color: rgba(51,51,51,.5);
			font-size: 12px;
		}

		a{
			display: flex;
			align-items: flex-start;
		}
	
		.update-progress{
			margin: 0;
			padding:3px;
			display: inline;
			font-size: 12px;
			font-family: "Inter";
		}
		}
		.community-updates::before{
			position: absolute;
			width: 4px;
			height: 100%;
			display: block;
			content: "";
			left: 0;
			top: 0;
			border-radius: 4px 0 0 4px;
		}
		.community-updates.community-updates_confirmed::before{
			background-color: red;
		}
		.community-updates.community-updates_unconfirmed::before{
			background-color: gold;
		}
	}
	
}