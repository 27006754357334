.project-audit{
  margin-bottom: 30px;

  .project-audit-title{
    display:  flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h4{
      display: flex;
      align-items: center;
      margin: 0;

      img{
        background-color: wheat;
        padding: 4px;
        margin-right: 10px;
        border-radius: 5px;
      } 
    }

    .title{
      font-size: 20px;
      font-weight: 600; 
      margin-right: 10px;
    }

    .description{
      font-size: 20px;
    }

    button{
      background-color: rgb(225, 170, 76);
      border-color: rgb(225, 170, 76);
      border-radius: 4px;
      color: rgb(51, 51, 51);
      font-weight: 500;
      letter-spacing: normal;
      border-color: transparent;
      font-family: "Inter";
    }
  }

  .card{
    box-shadow: 0 2px 16px #a5a5a50d;
    border-color: transparent;
    border-radius: 8px;
  }

  .card-body{
    display: flex;
  }

  .card-body .project-audit-left{
    flex: 0 0 25%;
  }

  .card-body .project-audit-left{
    .project-audit-github{
      background-color: #354559;
      padding:15px;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 15px;

      .project-audit-github-left{
        .project-audit-name{
          color: #e1aa4c;
          font-weight: 500;
          margin-bottom: 10px;
        }
        .project-audit-date{
          margin: 0;
          color: #fff;
        }
      } 

      .project-audit-github-right{
        color: white;
        float: right;
        display: block;
      }
    }

    .project-audit-file{
      border-radius: 8px;
      overflow: hidden;

      .project-audit-file-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        height: 200px;
        background-color: rgba(53,69,89,.05);
        text-align: center;

        img{
          margin-bottom: 15px;
        }

        p{
          margin: 0;
          color: #8d8d8d;
        }
      }
    }
  }

  .card-body .project-audit-right{
    flex: 0 0 75%;

    .project-audit-right-head{
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4{
        margin: 0;
      }

      button{
        background-color: #354559;
        border-color: #354559;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        letter-spacing: normal;
        border-color: transparent;
        font-family: "Inter";
        display: flex;
        align-items: center;

        svg{
          margin-right:10px;
        }
      }
    }

    .project-audit-right-content{
      padding: 15px;

      .project-audit-type{
        display: flex;
        gap:15px;

        span{
          background-color: rgba(77, 99, 128, 0.2);
          color: rgb(53, 69, 89);
          border-radius: 5px;
          padding: 3px 10px;
          font-size: 12px;
        }
      }

      .project-audit-detail{
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        gap: 80px;
        flex-wrap: wrap;

        .project-audit-detail-box{
          display: flex;
          flex-direction: column;
          text-align: left;
          gap : 10px;
          font-family: "Inter" !important;

          .title{
            text-transform: uppercase;
            color: rgba(51, 51, 51, 0.5);
            display: block;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.02em;
          }

          .content{
            color: rgba(51, 51, 51, 0.8);
            font-size: 14px;
            font-weight: 600;
            line-height: 32px;
          }
        }

        
      }

      .project-audit-description{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        text-align: left;
        gap : 10px;
        font-family: "Inter" !important;
        
        .title{
          text-transform: uppercase;
          color: rgba(51, 51, 51, 0.5);
          display: block;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          letter-spacing: 0.02em;
        }

        .content{
          color: rgba(51, 51, 51, 0.8);
          font-size: 14px;
          font-weight: 600;
          line-height: 32px;
        }
      }
    }
  }
}



@media screen and (max-width: 480px) {
	.project-audit .card-body{
		display: flex;
    flex-direction: column;
    padding:15px;
	}
  .project-audit .project-audit-title{
    h4{
      display: block;
      img{
        margin-bottom: 5px;
      }
    }
    button{
      display: none;
    }
  }

  .project-audit .project-audit-title .title,
  .project-audit .project-audit-title .description{
    display: block;
  }

  .project-audit .project-audit-title .title{
    font-size: 14px;
  }

  .project-audit .project-audit-title .description{
    font-size: 12px;
  }
  
  .project-audit .card-body .project-audit-right .project-audit-right-content .project-audit-detail{
    gap: 15px;
  }
  .project-audit .card-body .project-audit-right .project-audit-right-content .project-audit-detail .project-audit-detail-box{
    flex: 0 0 45%;
  }

  .project-audit-left{
    display: none;
  }

  .project-audit .card-body .project-audit-right .project-audit-right-head{
    padding: 0;

    h4{
      font-size: 14px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      svg{
        margin-right: 5px;
      }
    }
    
  }
  .project-audit .card-body .project-audit-right .project-audit-right-head button svg{
    display: none;
  }

  .project-audit .card-body .project-audit-right .project-audit-right-content{
    padding:0;
  }

  .project-audit .card-body .project-audit-right .project-audit-right-content .project-audit-description{
    margin-top: 20px;
  }

  .project-audit .card-body .project-audit-right .project-audit-right-content .project-audit-type{
    margin-top: 15px;
  }
}