@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.certik-theme{
    background-color: #fbfbfb;
    
    .owl-stage {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-box;
        display: box;
    }

    a, p, span{
        font-size: 12px;
        text-decoration: none;
    }
    
    a:hover {
        color: #e1aa4c;
        opacity: .8;
        text-decoration: none;
    }
    
    h1, h2, h3, h4, h5, h6{
        font-family: Inter;
        font-feature-settings: "calt" 0;
    }
    
    ul, ol{
        margin: 0;
        padding: 0;
        list-style-type: none;
    
        li{
            font-size: 14px;
        }
    }
}

.certik-loader{
    display: flex;
    align-items: center; 
    min-height: 726px; 
    height: 100vh; 
    justify-content: center
}

@media screen and (min-width: 1200px) {
    .certik-theme{
        .container, 
        .container-lg, 
        .container-md, 
        .container-sm, 
        .container-xl {
            max-width: 1220px;
        }
    }

    
}