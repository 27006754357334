.search-label{
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: capitalize;
}



.ant-select-arrow{
  font-size: 18px;
}

.label-image{
  border-radius: 20px;
  overflow: hidden;
  margin-right: 15px;
}

.label-image img{
  object-fit: cover;
  width: 40px;
  height: 40px;
}

.search-label a{
  padding-left: 15px;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.85);
}

.homepage-search{
  margin-top: -30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  height: 60px;
  box-shadow:  0 2px 16px #0000000d;

  .homepage-search-caption{
    background-color: #e1aa4c;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    flex: 0 0 25%;

    h4{
      margin: 0;
      color: white;
      font-size: 18px;
      text-align: center;
    }
  }

  .homepage-search-input{
    flex: 0 0 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border: none;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
      height: 100%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
      line-height: 60px;
      font-size: 18px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: start;
      text-transform: capitalize;
    }
  }

  
}

@media screen and (max-width: 480px){
    .homepage-search .homepage-search-caption{
      display: none;
    }

    .homepage-search .homepage-search-input{
      flex: 0 0 100%;
      
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
        height: 100%;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
}