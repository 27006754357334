.home-banner{
    background: url("https://www.certik.org/radar-bg.svg");
    background-position-x: 90%;
    background-repeat: no-repeat;
    background-color: #354559;
    padding-top: 108px;
    padding-bottom: 80px;

    h1.banner-title{
		color: #e8e8e8;
		font-size: 40px;
		font-weight: 500;
		letter-spacing: -.02em;
		line-height: 48px;
		margin: 0;
	}
	
	h1.banner-tagline{
		color: #e1aa4c;
		font-size: 20px;
		font-weight: 500;
		letter-spacing: -.02em;
		line-height: 100%;
		margin: 0;
		margin-top: 1.2em;
	}
}