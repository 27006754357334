.project-key-statistics{
  margin-bottom: 30px;
}

.project-key-statistics .key-statistics-wrapper{
  display: flex;
  flex-direction: row;

  .key-statistic-left{
    flex: 0 0 50%;
    padding-right: 15px;
    border-right: 1px solid #6b6b6b6e;

    p{
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      color: rgba(53,69,89,.8);
      
      span{
        font-size: 12px;
        font-weight: 500;
        font-family: 'Inter';
      }
    }
  }

  .key-statistic-right{
    flex: 0 0 50%;
    padding-left: 15px;

    p{
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      color: rgba(53,69,89,.8);
      
      span{
        font-size: 12px;
        font-weight: 500;
        font-family: 'Inter';
      }
    }
  }
}