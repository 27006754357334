
.project-detail	.project-rates{
	display: flex;
	flex-direction: column;

	.project-social-media{
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-bottom: 25px;

		ul{
			list-style-type: none;
		}

		ul li{
			float: left;
			margin: 0 5px;
		}

		ul li:last-child{
			margin-right: 0;
		}

		ul li a{
			padding: 10px;
			background: #f0f1f2;
			border-radius: 50%;
			display: flex;
			align-items: center;
		}
	}

	.project-key-statistics, .project-security-rates{
		.card{
			background-color: rgba(53,69,89,.04);
			border: none;
			border-radius: 8px 8px 8px 8px;
		}
	}
}

@media screen and (max-width: 480px) {
	.project-detail .project-rates .project-social-media{
		justify-content: start;
	}
}