.project-skynet{
  .project-skynet-title{
    display:  flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h4{
      display: flex;
      align-items: center;
      margin: 0;

      img{
        background-color: wheat;
        padding: 4px;
        margin-right: 10px;
        border-radius: 5px;
      } 
    }

    .title{
      font-size: 20px;
      font-weight: 600; 
      margin-right: 10px;
    }

    .description{
      font-size: 20px;
    }

    button{
      background-color: rgb(225, 170, 76);
      border-color: rgb(225, 170, 76);
      border-radius: 4px;
      color: rgb(51, 51, 51);
      font-weight: 500;
      letter-spacing: normal;
      border-color: transparent;
      font-family: "Inter";
    }
  }

  .project-skynet-content{
    .card{
      border-color: transparent;
    }

    .card .card-body{
      display: flex;
      gap:15px;
      // padding: 0;
      justify-content: center;
    }

    .project-skynet-left, .project-skynet-right{
      flex: 0 0 calc(50% - 15px);
      // height: 400px;
    }

    .project-skynet-left{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      background-color: rgba(53, 69, 89, 0.02);
      border-radius: 8px;
      padding-top: 15px;

      .radar-box{
        height: 350px;
        width: 350px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }
    }

    .project-skynet-right{
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;
    }

    .project-skynet-details{
      background-color: rgba(53, 69, 89, 0.02);
      padding: 15px;
      border-radius: 8px;
    }
  }
}



@media screen and (max-width: 480px) {
  
}