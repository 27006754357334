.project-description{
	.project-title{
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		h3{
			margin-bottom: 0;
			margin-right: 15px;
			font-weight: 600;
			font-size: 24px;
			text-transform: capitalize;
		}

		img{
			width: 40px;
			height: 40px;
			margin-right: 15px;
		}

		.project-rank{
			background-color: #354559;
			color: white;
			padding:5px 10px;
			margin-right: 10px;
			border-radius: 3px;
			font-size: 10px;
		}

		.project-boarding-date{
			background-color: #e0e0e0;
			color: #354559;
			padding:5px 10px;
			margin-right: 10px;
			border-radius: 3px;
			font-size: 10px;
		}
	}

	.project-detail-score{
		display: flex;
	}

	.project-detail-score .project-detail-score-progress{
		flex: 0 0 25%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.project-detail-score .project-detail-score-chart, .project-detail-market-chart{
		flex: 0 0 75%;

		> span{
			margin-bottom: 15px;
			display: block;
			font-family: "Inter";
			color: rgba(53, 69, 89, 0.8);
			font-size: 12px;
		}

		tspan{
			font-size: 10px;
			font-family: "Inter";
		}
	}

}

@media screen and (max-width: 480px) {
	.project-detail .project-title{
		display: block;
	}
	.project-detail .project-description .project-detail-score{
		gap: 15px;
		flex-direction: column;
	}

	.project-description .project-detail-score{
		overflow: scroll;
	}
}