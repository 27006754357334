.certik-footer{
	background-color: #354559;
	padding: 80px 0;
	line-height: 24px;

	.certik-footer-block{
		.certik-footer-block-title {
			span{
				margin-bottom: 24px;
				color: hsla(0,0%,100%,.8);
				font-weight: 500;
				font-family: 'Inter', sans-serif;
				display: block;
			}
		}
		.certik-footer-block-content{
			color: hsla(0,0%,100%,.8);

			ul li{
				margin-bottom:15px;
				font-weight: 300;
			}

			a{
				color: hsla(0,0%,100%,.8);;

				&:hover{
					color: #e1aa4c;
					opacity: .8;
					text-decoration: none;
				}
			}
		}
	}

	.certik-footer-block.certik-footer-desclaimer{
		img{
			height: auto;
			max-width: 150px;
			margin-bottom: 20px;
		}
		p{
			color: hsla(0,0%,100%,.8);
			margin-bottom: 5px;
		}
	}

	.newsletter-icon-wrapper{
		display: flex;
		flex-direction: row;
		width: 100%;
		flex-wrap: wrap;
		margin-bottom: 20px;

		.newsletter-icon{
			height: 40px;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(255, 255, 255, 0.1);
			border-radius: 50%;
			margin-right: 10px;
			margin-bottom: 10px;
		}

		.newsletter-icon img{
			height: auto;
			width: 20px;
		}
	}

	.newsletter-subscribe{
		a{
			color: hsla(0,0%,100%,.8);
			border: 1px solid hsla(0,0%,100%,.8);
			padding: 10px 25px;
			border-radius: 3px;
			width: 100%;
			display: block;
			text-align: center;
			margin-bottom: 15px;
		}
		p{
			color: hsla(0,0%,100%,.8);
			font-size: 12px;
		}
	}
}