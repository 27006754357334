
.project-detail-tabs-container{
  margin-bottom: 25px;

	.project-detail-tabs-navigation{
		border: none;
		gap:15px;
		flex-wrap: wrap;
		display: inline-flex;


		.tabs-navigation{
			background: rgb(234, 235, 237);
			color: #3545593a;

			&::before{
				display: block;
				content: '';
				height: 4px;
				width: 100%;
				background: #838486;
				border-top-right-radius: 4px;
				border-top-left-radius: 4px;
			}
			
			&.active{
				background-color: rgba(53, 69, 89, 0.04);
				color: #35455979;

				&::before{
					background: #4d6380;
				}
			}
			
			.nav-link{
				font-weight: 600;
				font-size: 16px;
				color: #354559;
				border: none;
				padding-left: 15px;
				padding-right: 15px;
				display: flex;
				align-items: center;
				gap:20px;

				&:focus, &:hover{
					border: none;
					cursor: pointer;
				}
			}
		}
	}

	.project-detail-tabs-content{
		background-color: rgba(53, 69, 89, 0.04);
		padding: 15px;
		border-radius: 0 8px 8px 8px;
	}

}